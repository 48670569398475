import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import * as styles from "./blog.module.scss"
import * as mainStyles from "../../styles/index.module.scss"
import { Seo } from "../../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Head = () => <Seo title="Blog" />

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Layout>
    <h1>Blog</h1>
    {edges
      .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
      .map(edge => {
        let img = getImage(
          edge.node.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData
        )

        return (
          <Link
            className={mainStyles.chainCard}
            to={`/blog${edge.node.frontmatter.slug}`}
          >
            <div className={mainStyles.flexRow}>
              <div style={{ flex: 2 }}>
                {edge.node.frontmatter.date}
                <h3 style={{ marginTop: "0.5rem" }}>
                  {edge.node.frontmatter.title}
                </h3>
                <p>{edge.node.excerpt}</p>
              </div>
              <GatsbyImage image={img} className={styles.previewImage} />
            </div>
          </Link>
        )
      })}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1920)
              }
            }
          }
        }
      }
    }
  }
`
